@import 'src/app/system/shared/dark-theme-colors';

select {
    height: 44px;

    padding-left: 20px;
    padding-right: 40px;

    border: none;
    outline: none;
    border-radius: 16px;

    background-color: $dark-background;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("../../assets/select-icon.svg");
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 20px;

    color: $component-text-white;
    text-overflow: ellipsis; // Добавляем многоточие
    white-space: nowrap; // Запрещаем перенос строк
    overflow: hidden; // Обрезаем все, что не помещается в область
}

select:hover,
select:focus {
    outline: none !important;
    border:1px solid $component-hover-focus-border;
}