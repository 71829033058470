@import 'src/app/system/shared/dark-theme-colors';

// Для того, чтобы корректно работал скроллбар, нужно оборачивать textarea в div

.textarea-div {
    padding: 10px;

    border-radius: 16px;

    background-color: $dark-background;
}

.textarea-div:focus-within,
.textarea-div:hover {
    outline: none !important;
    border: 1px solid $component-hover-focus-border !important;
}

.main-textarea {
    width: 100%;
    height: 100%;
    flex-shrink: 0;

    padding-right: 10px;
    padding-left: 10px;

    font-family: 'Inter', serif;
    color: $component-text-white;
    background-color: $dark-background;

    border: none;
    outline: none;
    resize: none;

    cursor: auto;
}

.main-textarea::-webkit-scrollbar {
    width: 6px;
    height: 45px;
    flex-shrink: 0;
}

.main-textarea::-webkit-scrollbar-thumb {
    background-color: $scroll-background;
    border-radius: 30px;
}

.main-textarea::-webkit-scrollbar-thumb:hover {
    background-color: $scroll-hover-background;
}