.mat-date-range-input-mirror {
    display: none !important;
}

.mat-date-range-input {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: auto !important;
}

.mat-date-range-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.mat-date-range-input-end-wrapper {
    flex-grow: 0;
}

// настройки для иконки календаря при выборе даты
.mat-mdc-icon-button.mat-mdc-button-base {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
}

.mat-mdc-icon-button img {
    --mdc-icon-button-icon-size: 35px;
}

.mat-date-range-input-inner {
    position: static !important;
}

.mat-date-range-input-separator {
    display: none;
}