@import 'src/app/system/shared/dark-theme-colors';

//remove "input" arrow
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

input:hover:enabled,
input:focus:enabled {
    outline: none !important;
    border:1px solid $component-hover-focus-border;
}

.main-input {
    outline: none;
    border: none;
    border-radius: 16px;

    color: $component-text-white;
    font-family: 'Inter', serif;

    background-color: $dark-background !important;

    padding-left: 20px !important;
    padding-right: 20px !important;
}