@import "src/app/system/shared/dark-theme-colors";

// изменяет размер текста в селекте пагинатора
.mdc-list-item__primary-text {
    font-size: 12px !important;
}

// изменяет цвет выделенного элемента в селекте пагинатора
.mdc-list-item__primary-text {
    color: $component-text-white !important;
}