$background: #191A1C;
$dark-background: #151618;

$light-red: #F2B8B5;
$light-green: #7AB979;

$text-gray: #87899B;
$text-second-gray: #777797;
$text-white: #EEEFFF;
$text-current-progress-value: #454FFF;

$component-hover-focus-border: #87899B;
$component-text-white: #F1F1F1;

$dialog-window-background: #212128;

$card-background: #212128;
$card-inner-blocks-background: #2C2C3D;

$button-hover-background: rgba(208, 188, 255, 0.08);
$button-active-background: rgba(208, 188, 255, 0.18);
$button-disabled-border: #87899B;
$secondary-button-border: #555769;
$main-button-border: #F1F1F1;

$toggle-button-border: #87899B;
$toggle-button-active-background: #4A4458;

$scroll-hover-background: #646567;
$scroll-background: #CACDD233;

$progress-background: rgba(85, 94, 239, 0.2);
$progress-value-background: rgba(69, 79, 255, 1);

$input-search-border: #4A4458;

$selected-item-background: rgba(208, 188, 255, 0.18);

$error: #CC1313;