@import 'src/app/system/shared/dark-theme-colors';

// в GoalListComponent скармливается для компонента MatDialog
.my-panel {
    max-width: 508px !important;
}

// глобальная настройка для MatDialog
.mdc-dialog__surface {
    background-color: $dialog-window-background !important;
}

// border-radius для всплывающего окна создания цели
.mat-mdc-dialog-container .mdc-dialog__surface {
    --mdc-dialog-container-shape: 16px;
}