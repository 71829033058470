@import '~normalize.css';
@import "src/app/uikit/main-button";
@import "src/app/uikit/secondary-button";
@import "src/app/uikit/input";
@import "src/app/uikit/textarea";
@import "src/app/uikit/select";
@import "material-design-custom-styles/md-paginator";
@import "material-design-custom-styles/md-dialog";
@import "material-design-custom-styles/md-date-picker";
@import "material-design-custom-styles/md-menu";

/* rubik-300 - cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('resources/fonts/rubik-v23-cyrillic-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/rubik-v23-cyrillic-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-regular - cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('resources/fonts/rubik-v23-cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/rubik-v23-cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500 - cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('resources/fonts/rubik-v23-cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/rubik-v23-cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-600 - cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('resources/fonts/rubik-v23-cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/rubik-v23-cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-italic - cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: local(''),
    url('resources/fonts/rubik-v23-cyrillic-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/rubik-v23-cyrillic-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500italic - cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: local(''),
    url('resources/fonts/rubik-v23-cyrillic-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/rubik-v23-cyrillic-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('resources/fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: local(''),
    url('resources/fonts/Inter/Inter-Thin.ttf') format('truetype');
}

html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    font-family: 'Rubik', serif;
    font-style: normal;
    font-size: 16px;
    line-height: 1.2;
    font-weight: normal;
    // TODO: используется для светлой темы на экране логина. Убрать после рефакторинга входа в систему
    color: #222222;
}

p {
    margin: 0;
}