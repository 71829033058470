@import 'src/app/system/shared/dark-theme-colors';

.secondary-button {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 8px;

    font-family: Inter, serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: $component-text-white;

    border: 1px solid $secondary-button-border;
    border-radius: 100px;
    background-color: transparent;

    padding: 5px 10px 5px 10px;

    outline: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor: pointer;
}

.secondary-button:disabled {
    color: $text-gray;
}

.secondary-button:focus:enabled,
.secondary-button:hover:enabled {
    background: $button-hover-background;
}

.secondary-button:active:enabled {
    background: $button-active-background;
}