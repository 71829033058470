@import 'src/app/system/shared/dark-theme-colors';

.mat-mdc-menu-item-text {
    display: flex;
    gap: 10px;
    align-items: center;
    color: $text-white;
}

.mat-mdc-menu-content {
    background-color: $dialog-window-background;
}